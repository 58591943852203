import React from 'react';
import {Row, List, Image} from 'antd'
import {CodeOutlined, BankTwoTone, StarTwoTone, SmileTwoTone} from '@ant-design/icons'
import my_picture from '../assets/headshot.JPG'
import '../App.css';
import 'antd/dist/antd.css';


function AboutMe() {

    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };


    return (
        <Row>
            <Image 
            src={my_picture}
            preview={false}
            style={{ width: 250, display: 'inline-block', marginTop: '3em', marginLeft: '15em' }}>
            
            {/* I'm a full-stack Software Engineer. I love learning new things and working with cool people! */}
            </Image>

            <List
            layout='horizontal'
            header={<h2>Hi, My name is Kyle O'Connor!</h2>}
            style={{ width: 400, margin: '3em' }}
            split={false}>
            
                <List.Item>
                    <CodeOutlined /> I am currently working full-time as a Software Engineer.
                </List.Item>
                <List.Item>
                    <BankTwoTone twoToneColor='#C20000'/> I graduated in 2020 with both my Bachelor of Science 
                    and Master of Engineering in Computer Science from 
                    the University of Cincinnati (Go Bearcats!).
                </List.Item>
                <List.Item>
                <StarTwoTone twoToneColor='#0087B4'/> I am a self-starter and avid hat collector!
                </List.Item>
                <List.Item>
                <SmileTwoTone twoToneColor='#3FC200'/> I am passionate about solving real-world 
                    problems and even more passionate about people!
                </List.Item>

            </List>
        </Row>


        
    );
}

export default AboutMe;