import React, {useEffect, useState} from 'react';
import {Timeline, Modal, Tag} from 'antd'
import 'antd/dist/antd.css';


export default function EmploymentModal(props) {

    return(
        <Modal 
        visible={props.visible}
        onCancel={props.handleCancel}
        onOk={props.handleOk}
        title={props.position + ' @ ' + props.company}
        footer={props.stack.map((item, index) => {
            return (<Tag>{item}</Tag>)
        })}>
            {props.description.split(';').map((item, index) =>{
                return (<p>{item}</p>)
            })}
        </Modal>
    )
}