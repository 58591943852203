import React from 'react';
import {Row, Col, List, Image, Comment, Divider} from 'antd'
import {CodeOutlined, BankTwoTone, StarTwoTone, SmileTwoTone} from '@ant-design/icons'
import EmploymentHistoryTimeline from './components/EmploymentHistoryTimeline'
import AboutMe from './components/About'
import TechSkills from './components/TechSkills';
import './App.css';
import 'antd/dist/antd.css';


function App() {

  return (
    
    <div>
      <AboutMe />
      <Divider />
      <TechSkills/>
      <Divider />      
      <EmploymentHistoryTimeline />
      {/* // Areas of interest */}
      {/* // Contact & Social Media */}
        

      
    </div>

  );
}

export default App;
