import React, {useState} from 'react';
import {Timeline, Modal, Button} from 'antd'
import EmploymentModal from './EmploymentModal'
import 'antd/dist/antd.css';

const employment_details = {
  'diebold1':{
    company: 'Diebold Nixdorf',
    start: 'August 2016',
    finish: 'December 2016',
    position: 'Computer Science/Engineer Co-op',
    team: 'Logical Security Team',
    tech_stack: ['C#', '.NET', 'Javascript', 'Git', 'TFS'],
    description: 'I was an intern with the ATM Logical Security Team at Diebold Nixdorf, and I: \
    ; Tested security software on ATM processors and configured ATM processors \
    ; Debugged internal tools and software used to handle/read security measures on the ATM processor \
    ; Developed and versioned an internal windows service in C#.net with XML \
    ; Updated backend process for an internal website'
  },
  'diebold2':{
    company: 'Diebold Nixdorf',
    start: 'May 2017',
    finish: 'August 2017',
    position: 'Computer Science/Engineer Co-op',
    team: 'Logical Security Team',
    tech_stack: ['C#', '.NET', 'Javascript', 'Git', 'TFS'],
    description: 'I was an intern for the ATM Logical Security Team at Diebold Nixdorf, and I: \
    ; Helped the team with day-to-day tasks \
    ; Tested security software compatibility with different environments \
    ; Developed search and filter features for both company and vendor administrator websites \
    ; Created solutions and documentation for new environments to be used by technicians' 
  },
  'worldpay':{    
    company: 'Worldpay',
    start: 'May 2018',
    finish: 'August 2018',
    position: 'Software Engineering Intern',
    team: 'Enterprise Platform and Tooling Team',
    tech_stack: ['Python', 'Linux', 'Git'],
    description: 'I was a member of the Data Platform and Tooling Team, and I: \
    ; Implemented Business Objects Platform upgrade/patching on Linux server \
    ; Analyzed and documented SQL and MATLAB scripts \
    ; Developed in a Scaled Agile Framework \
    ; Worked with SRO to submit change requests and participate in production implementation \
    ; Provided Business Objects and WebFOCUS platform support'
  },
  'urbanloop':{
    company: 'Urbanloop',
    start: 'January 2019',
    finish: 'July 2019',
    position: 'Command and Control Developer Intern',
    team: 'Command and Control Team',
    tech_stack: ['Python', 'Structured Text', 'PLC', 'Git'],
    description: 'I was a part of the Stage Master team, and I: \
    ; Designed and constructed conceptual pod-based transportation system \
    ; Developed a trafficking system for the pods (Python, PyModbus, Structured Text, Wago PLC, E!Cockpit, IFM Controller, Ruby) \
    ; Created a GUI application for users to track, order, and prime pods for travel (PyModbus, Tkinter) \
    ; Managed and oversaw a team of first-year French engineering students of different disciplines \
    ; Communicated technical work to non-technical counterparts and media outlets'
  },
  'edaptive':{
    company: 'Edaptive Computing Inc.',
    start: 'Auguest 2019',
    finish: 'December 2019',
    position: 'Machine Learning Research Intern',
    team: 'University of Cincinnati Innovation',
    tech_stack: ['Python', 'PyTorch', 'Tensorflow'],
    description: "Researched and constructed CNN-LSTM models for anomaly \
      detection with PyTorch and TensorFlow."
  },
  'pg':{
    company: 'Proctor & Gamble',
    start: 'December 2020',
    finish: 'June 2020',
    position: 'Research Intern',
    team: 'Lumi by Pampers',
    tech_stack: ['Python', 'VBA', 'Git'],
    description: "Designed and developed a data quality automation for the Lumi by Pampers \
      product suite beta testing group in VBA and Python. "
  },
  'chase':{
    company: 'J.P. Morgan Chase & Co.',
    start: 'July 2020',
    finish: 'Present',
    position: 'Software Engineer',
    team: 'Risk & Controls Automation',
    tech_stack: ['Python', 'Javascript', 'React', 'SQL', 'Git'],
    description: "Built an automation platform for risk reduction and data quality issue remediation.\
      ; Assembled API based back-end and data ingestion process (Python, API, MySQL, Cloud Foundry) \
      ; Developed a front-end portal for customer interaction (React JS, Vue JS) \
      ; Aided in design of application architecture, data model, and app resiliency. \
      ; Collaborated with and demonstrated to users and stakeholders to gather and refine requirements."
  },

}



function EmploymentHistoryTimeline() {
  const [modalVisible, setModalVisible] = useState(false);
  // const [selectedHistoryItem, setSelectedHistoryItem] = useState({});
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [selectedStack, setSelectedStack] = useState([]);

  const showModal = (item) => {
    setModalVisible(true)
    setSelectedCompany(item.company)
    setSelectedPosition(item.position)
    setSelectedDescription(item.description)
    setSelectedStack(item.tech_stack)
  }

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  let employmentItems = Object.keys(employment_details).map((item, index)=>{
    return(     
          <Timeline.Item label={employment_details[item].start}>
            <Button onClick={() => 
              showModal(employment_details[item])}>
              {employment_details[item].company}
            </Button>
          </Timeline.Item>
    )
    })

  return (
    <div>
      <h3 style={{width: 300, display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>Employment History</h3>
      <Timeline reverse='true' mode='left'>
        {employmentItems}
      </Timeline>

      <EmploymentModal
        visible={modalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        company={selectedCompany}
        position={selectedPosition}
        description={selectedDescription}
        stack={selectedStack}
        />
    </div>



  );
}

export default EmploymentHistoryTimeline;