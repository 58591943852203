import React from 'react';
import {Row, List, Avatar} from 'antd'
import pythonIcon from '../assets/python.png'
import javascriptIcon from '../assets/javascript.png'
import reactIcon from '../assets/react.png'
import sqlIcon from '../assets/sql.png'
import javaIcon from '../assets/java.png'
import phpIcon from '../assets/php.png'
import cppIcon from '../assets/cpp.png'
import gitIcon from '../assets/git.png'

export default function TechSkills(){

    return(
        <div>
            <h3 style={{width: 300, display: 'block', marginLeft: 'auto', marginRight: 'auto'}}>
                Some technologies I've worked with...
            </h3>
            <Row>
                <List
                style={{ width: 300, display: 'block', marginLeft: 'auto'}}
                itemLayout="horizontal">
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={pythonIcon} />}
                            title="Python"
                            description="Exposure to Python in 2015. 7+ years of academic and professional experience."
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={javascriptIcon} />}
                            title="Javascript"
                            description="First exposure to Javascript in 2017. 2 years of academic and professional experience."
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={reactIcon} />}
                            title="React JS"
                            description="First exposure to React in 2020. Worked with React JS for one year in a professional setting."
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={sqlIcon} />}
                            title="SQL"
                            description="First exposure to SQL in 2016 through MySQL. 
                                        Worked with MySQL, Oracle SQL, and MariaDB and SQL queries for 4 years in 
                                        an academic and professional setting."
                        />
                    </List.Item>
                </List>
                <List
                style={{ width: 300, display: 'block', marginRight: 'auto'}}
                itemLayout="horizontal">
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={javaIcon} />}
                            title="Java"
                            description="Exposure to Java in 2016. Three years of academic exposure to Java."
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={phpIcon} />}
                            title="PHP"
                            description="Exposure to PHP in 2020 for a client. Picked it up quickly and created a mobile app 
                                        with a PHP back-end to easily plug into WordPress."
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={cppIcon} />}
                            title="C++"
                            description="Exposure to C++ in 2015. Used for five years in an academic setting."
                        />
                    </List.Item>
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={gitIcon} />}
                            title="Git++"
                            description="Exposure to Git in 2016. Used for 6+ years in an academic and professional setting."
                        />
                    </List.Item>
                </List>
            </Row>
        </div>

    );

}